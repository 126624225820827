<div *ngIf="form && !error" class="header-pane uf-app-bar transparent">
    <uc-builder-header />
</div>

<uf-panel *ngIf="!error" class="content-pane">
    <div class="uf-container-lg">

        <uf-skeleton *ngIf="!form" />

        <div *ngIf="form" [formGroup]="form">

            <div class="uf-box gap-top">
                <div class="uf-app-bar flat accent">
                    <div class="title">Details</div>
                </div>
                <div class="uf-grid pad">
                    <uf-text [formControlName]="controlKeys.ConsoleName" [label]="consoleNameLabel" class="col-12">
                        <span class="suffix">*</span>
                    </uf-text>
                </div>
            </div>

            <div class="uf-box gap-top">
                <div class="uf-app-bar flat accent">
                    <div class="title">Source</div>
                </div>
                <div class="uf-grid pad">
                    <uf-select [options]="sourceTypes" [formControlName]="controlKeys.SourceType" label="Source"
                        placeholder="Select Source" valueProperty="identifier" class="col-12">
                        <span class="suffix">*</span>
                    </uf-select>
                    <uf-autocomplete *ngIf="form.get(controlKeys.Bucket)?.enabled"
                        [formControlName]="controlKeys.Bucket" [options]="buckets"
                        (searchChange)="searchBuckets($event)" (valueChange)="bucketChange($event)"
                        label="Form Data Repository" placeholder="Search form data repository" nameProperty="name"
                        class="col-12">
                        <span class="suffix">*</span>
                    </uf-autocomplete>
                </div>
            </div>

            <div class="uf-box gap-top">
                <div class="uf-app-bar flat accent">
                    <div class="title">Target</div>
                </div>
                <div class="uf-grid pad">
                    <uf-autocomplete [options]="formOptions" [formControlName]="controlKeys.TargetForm"
                        (searchChange)="searchForms($event)" (valueChange)="targetFormChange($event)" label="Form"
                        placeholder="Search Form" nameProperty="name" class="col-12">
                        <span class="suffix">*</span>
                    </uf-autocomplete>
                    <uf-autocomplete *ngIf="form.get(controlKeys.TargetTransition)?.enabled"
                        [formControlName]="controlKeys.TargetTransition" [nameProperty]="transitionName"
                        [options]="transitions" (searchChange)="searchTransitions($event)"
                        (valueChange)="transitionChange($event)" label="Workflow Transition"
                        placeholder="Search workflow transition" class="col-12">
                        <span class="suffix">*</span>
                    </uf-autocomplete>
                </div>
            </div>

            <div *ngIf="targetFields" class="uf-box gap-top">
                <div class="uf-app-bar flat accent">
                    <div class="title">Data Mapping</div>
                </div>
                <div class="uf-grid pad">

                    <table *ngIf="metaFields?.length" class="col-12 small uf-table accent fixed">
                        <thead>
                            <tr>
                                <th>
                                    <div class="row space-children--small">
                                        <uf-expander-button [isExpanded]="isExpanded" (click)="toggle()" />
                                        <span>Source Data</span>
                                    </div>
                                </th>
                                <th>Target Data</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="isExpanded">
                            <tr *ngFor="let field of metaFields">
                                <td>
                                    <div class="row space-children--small wrap">
                                        <ng-container *ngTemplateOutlet="metaField; context: {field: field.source}" />
                                    </div>
                                </td>
                                <td>
                                    <div class="row space-children--small wrap">
                                        <ng-container *ngTemplateOutlet="metaField; context: {field: field.target}" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <uc-field-mapping-table *ngIf="inputMapControl" [control]="inputMapControl"
                        [targetFields]="targetFields" [sourceFields]="sourceFields" class="col-12" />
                </div>
            </div>
        </div>
    </div>
</uf-panel>

<uc-error-message [error]="error" />

<ng-template #metaField let-field='field'>
    <uf-icon *ngIf="field.icon" [name]="field.icon" class="light" />
    <span *ngIf="field.label" class="light">{{ field.label }}</span>
    <span *ngIf="field.identifier" class="light">(<code>{{ field.identifier }}</code>)</span>
    <span *ngIf="field.expression" class="light"><code>{{ field.expression }}</code></span>
</ng-template>