import { Injectable, OnDestroy, inject } from '@angular/core';
import { UfControl, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { SchemaTransition, hasLengthAtLeast, isStringNotEmpty } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { ActivityType, ConsoleInfo, FormSubmittedCondition, Integration as IntegrationData, IntegrationFeature, NewWorkflowRule, RoleAddedCondition, WorkflowActivityTimer as TimerData, UcClient, UcFormBucketClient, UcIntegrations, UcWorkflow, WorkflowActivityInfo, WorkflowCondition, WorkflowEvent, WorkflowEventType, WorkflowRule, WorkflowRuleActivity, WorkflowState } from 'client';

import { reduceExpressionToSingleLine, sortByActivityType } from './workflow-functions';

interface ChangeEventTypeData {
    type: WorkflowEventType;
    bucket: UfControl;
    transitions: UfControl;
    timer: UfControl;
    integration: UfControl;
    forms: UfControl;
    roles: UfControl;
    feature: UfControl;
    includeExternal: UfControl;
    includeNewUser: UfControl;
}

export type SchemaTransitionDescription = SchemaTransition & { description: string };

export enum ControlKeys {
    Id = 'id',
    State = 'state',
    Label = 'label',
    ConsoleName = 'consoleName',
    EventType = 'eventType',
    Condition = 'condition',
    Bucket = 'bucket',
    Transitions = 'transitions',
    Forms = 'forms',
    Expression = 'expression',
    Activities = 'activities',
    Type = 'type',
    Activity = 'activity',
    Timer = 'timer',
    Integration = 'integration',
    Feature = 'feature',
    Roles = 'roles',
    IncludeNewUser = 'includeNewUser',
    IncludeExternal = 'includeExternal',
    TransitionOptions = 'transitionOptions',
}

export interface WorkflowRuleActivityModel {
    id: string;
    label: string;
    type: ActivityType;
    activity?: WorkflowActivityInfo;
}

export interface WorkflowRuleFormModel extends ConsoleInfo {
    id: string;
    state: WorkflowState;
    eventType: WorkflowEventType;
    timer?: TimerData;
    integration?: IntegrationData;
    feature?: IntegrationFeature;
    bucket?: string;
    transitions?: SchemaTransition[];
    condition?: WorkflowCondition;
    activities: WorkflowRuleActivityModel[];
    transitionOptions?: SchemaTransitionDescription[];
}

@Injectable()
export class WorkflowRuleFormController implements OnDestroy {

    private subscriptions = new Subscription();
    private ufb = inject(UfFormBuilder);
    private ucClient = inject(UcClient);
    private ucWorkflow = inject(UcWorkflow);
    private ucIntegrations = inject(UcIntegrations);
    private ucFormBucketClient = inject(UcFormBucketClient);
    private readonly fieldRequiredValidator = ValidatorFunctions.required('Field required');

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    buildRoot(data: WorkflowRuleFormModel | null): UfControlGroup {
        const eventType = this.ufb.control({ value: data?.eventType, disabled: !!data?.id }, this.fieldRequiredValidator);
        const timer = this.ufb.control({ value: data?.timer, disabled: data?.eventType !== WorkflowEventType.Timer }, this.fieldRequiredValidator);
        const bucket = this.ufb.control({ value: (data?.condition as FormSubmittedCondition | undefined)?.bucket, disabled: data?.eventType !== WorkflowEventType.FormSubmitted || !!data?.id }, this.fieldRequiredValidator);
        const forms = this.ufb.control({ value: (data?.condition as FormSubmittedCondition | undefined)?.forms, disabled: data?.eventType !== WorkflowEventType.FormSubmitted });
        const transitions = this.ufb.control({ value: (data?.condition as FormSubmittedCondition | undefined)?.transitions, disabled: data?.eventType !== WorkflowEventType.FormSubmitted }, this.fieldRequiredValidator);
        const integration = this.ufb.control({ value: data?.integration, disabled: data?.eventType !== WorkflowEventType.ApiEvent }, this.fieldRequiredValidator);
        const feature = this.ufb.control({ value: data?.feature, disabled: data?.eventType !== WorkflowEventType.ApiEvent }, this.fieldRequiredValidator);
        const roles = this.ufb.control({ value: (data?.condition as RoleAddedCondition | undefined)?.roles, disabled: data?.eventType !== WorkflowEventType.RoleAdded });
        const includeExternal = this.ufb.control({ value: (data?.condition as RoleAddedCondition | undefined)?.includeExternal, disabled: data?.eventType !== WorkflowEventType.RoleAdded });
        const includeNewUser = this.ufb.control({ value: (data?.condition as RoleAddedCondition | undefined)?.includeNewUser, disabled: data?.eventType !== WorkflowEventType.RoleAdded });

        const condition = this.ufb.group({
            [ControlKeys.Expression]: data?.condition?.expression,
            [ControlKeys.Forms]: forms,
            [ControlKeys.Bucket]: bucket,
            [ControlKeys.Transitions]: transitions,
            [ControlKeys.Roles]: roles,
            [ControlKeys.IncludeExternal]: includeExternal,
            [ControlKeys.IncludeNewUser]: includeNewUser,
        });

        const root = this.ufb.group({
            [ControlKeys.Id]: data?.id,
            [ControlKeys.State]: data?.state,
            // [ControlKeys.Label]: [data?.label, this.fieldRequiredValidator],
            [ControlKeys.ConsoleName]: [data?.consoleName, this.fieldRequiredValidator],
            [ControlKeys.EventType]: eventType,
            [ControlKeys.Timer]: timer,
            [ControlKeys.Integration]: integration,
            [ControlKeys.Feature]: feature,
            [ControlKeys.Condition]: condition,
            [ControlKeys.TransitionOptions]: [data?.transitionOptions ?? []],
            [ControlKeys.Activities]: this.ufb.array(
                (data?.activities ?? []).map((a) => this.buildActivityControl(a)),
                ValidatorFunctions.custom((v) => v.length > 0, 'At least one activity is required'),
            ),
        });

        this.subscriptions.add(integration.valueChanges.subscribe(() => feature.enable()));

        return root;
    }

    buildActivityControl(activity?: WorkflowRuleActivity): UfControlGroup {
        const activityControl = this.ufb.control(activity?.consoleName, this.fieldRequiredValidator);
        const activityLabelControl = this.ufb.control(activity?.consoleName);

        this.subscriptions.add(activityControl.valueChanges.subscribe((activityInfo?: WorkflowActivityInfo) => {
            activityLabelControl.setValue(activityInfo?.consoleName);
        }));

        return this.ufb.group({
            [ControlKeys.Id]: activity?.id,
            [ControlKeys.Label]: activityLabelControl,
            [ControlKeys.Activity]: activityControl,
            [ControlKeys.Type]: activity?.type,
        });

    }

    async toFormModel(workflowRule: WorkflowRule): Promise<WorkflowRuleFormModel> {
        const formValue: WorkflowRuleFormModel = {
            id: workflowRule.id,
            state: workflowRule.state,
            consoleName: workflowRule.consoleName,
            eventType: workflowRule.event.type,
            condition: workflowRule.condition,
            activities: workflowRule.activities.sort(sortByActivityType),
        };

        let event;

        switch (workflowRule.event.type) {
            case WorkflowEventType.Timer:
                {
                    const timerId = workflowRule.event.timerId;

                    if (isStringNotEmpty(timerId)) {
                        event = await this.ucWorkflow.getActivity<TimerData>(timerId);
                        formValue.timer = event;
                    }

                    break;
                }

            case WorkflowEventType.ApiEvent:
                {
                    const integrationId = workflowRule.event.integrationId;
                    const featureId = workflowRule.event.featureId;

                    if (isStringNotEmpty(integrationId) && isStringNotEmpty(featureId)) {
                        event = await this.ucIntegrations.get(integrationId);
                        formValue.integration = event;
                        const { features } = await this.ucClient.getAvailableIntegration(event.provider.id);

                        formValue.feature = features.find((f) => f.id === featureId);
                    }

                    break;
                }

            case WorkflowEventType.FormSubmitted:
                {
                    const { bucket, transitions } = (formValue.condition as FormSubmittedCondition);

                    if (!isStringNotEmpty(bucket)) {
                        break;
                    }

                    const { transitions: schemaTransitions } = await this.ucFormBucketClient.get(bucket);

                    formValue.transitionOptions = schemaTransitions.map((transition) => this.mapSchemaTransitionDescription(transition));
                    if (transitions) {
                        for (let index = 0; index < transitions.length; index++) {
                            const transitionAtIndex = transitions[index];

                            if (transitionAtIndex) {
                                const transitionOption = formValue.transitionOptions.find((to) => to.action === transitionAtIndex.action && to.source === transitionAtIndex.source);

                                if (transitionOption) {
                                    transitions[index] = transitionOption;
                                }
                            }
                        }
                    }
                }
        }

        return formValue;
    }

    toDataModel(value: WorkflowRuleFormModel): WorkflowRule | NewWorkflowRule | null {

        const event = this.getWorkflowEvent(value);
        const workflowRule: NewWorkflowRule = {
            event,
            consoleName: value.consoleName,
            activities: value.activities.map((a) => ({ id: a.id, consoleName: a.label, type: a.type, label: a.label })),
        };
        const condition = this.reduceWorkflowCondition(value.condition);

        if (condition) {
            workflowRule.condition = condition;
        }

        if (!value.id) {
            return workflowRule;
        }

        return {
            id: value.id,
            state: value.state,
            ...workflowRule,
        };
    }

    resetEventForm({ timer, bucket, transitions, forms, type: v, integration, roles, feature, includeNewUser, includeExternal }: ChangeEventTypeData) {
        if (v === WorkflowEventType.Timer) {
            timer.enable();
            // Reset controls
            bucket.reset({ disabled: true, value: null });
            // TODO look at chips component as reset requires an empty array otherwise it will kill the stack
            forms.reset({ disabled: true, value: [] });
            transitions.reset({ disabled: true, value: [] });
            integration.reset({ disabled: true, value: null });
            feature.reset({ disabled: true, value: null });
            // role added
            roles.reset({ disabled: true, value: [] });
            includeExternal.reset({ disabled: true, value: null });
            includeNewUser.reset({ disabled: true, value: null });
        } else if (v === WorkflowEventType.ApiEvent) {
            integration?.enable();
            feature.reset({ disabled: true, value: null });
            timer.reset({ disabled: true, value: null });
            bucket.reset({ disabled: true, value: null });
            // TODO look at chips component as reset requires an empty array otherwise it will kill the stack
            forms.reset({ disabled: true, value: [] });
            transitions.reset({ disabled: true, value: [] });
            roles.reset({ disabled: true, value: [] });
            includeExternal.reset({ disabled: true, value: null });
            includeNewUser.reset({ disabled: true, value: null });
        } else if (v === WorkflowEventType.FormSubmitted) {
            bucket.enable();
            forms.enable();

            // TODO look at chips component as reset requires an empty array otherwise it will kill the stack
            transitions.reset({ disabled: true, value: [] });
            integration.reset({ disabled: true, value: null });
            feature.reset({ disabled: true, value: null });
            timer.reset({ disabled: true, value: null });
            roles.reset({ disabled: true, value: [] });
            includeExternal.reset({ disabled: true, value: null });
            includeNewUser.reset({ disabled: true, value: null });
        } else if (v === WorkflowEventType.RoleAdded) {
            roles.enable();
            includeExternal.reset({ disabled: false, value: false });
            includeNewUser.reset({ disabled: false, value: false });

            bucket.reset({ disabled: true, value: null });
            timer.reset({ disabled: true, value: null });
            forms.reset({ disabled: true, value: [] });
            transitions.reset({ disabled: true, value: [] });
            integration.reset({ disabled: true, value: null });
            feature.reset({ disabled: true, value: null });
        }
    }

    mapSchemaTransitionDescription({ source, action, target }: SchemaTransition): SchemaTransitionDescription {
        return {
            source, action, target,
            description: target ? `${source} > ${target} (${action})` : `${source} (${action})`,
        };
    }

    private getWorkflowEvent(formValue: WorkflowRuleFormModel): WorkflowEvent {
        const timer = formValue.timer?.id;

        if (timer != null) {
            return {
                timerId: timer,
                type: WorkflowEventType.Timer,
            };
        }

        const integrationId = formValue.integration?.id;
        const featureId = formValue.feature?.id;

        if (integrationId != null && featureId != null) {
            return {
                type: WorkflowEventType.ApiEvent,
                featureId, integrationId,
            };
        }

        return { type: formValue.eventType };
    }

    private reduceWorkflowCondition(inputCondition?: WorkflowCondition): WorkflowCondition | null {
        if (!inputCondition) {
            return null;
        }

        if (inputCondition.expression) {
            inputCondition.expression = reduceExpressionToSingleLine(inputCondition.expression);
            inputCondition.expression = inputCondition.expression ? inputCondition.expression : undefined;
        }

        let condition: WorkflowCondition | null = null;

        for (const key of Object.keys(inputCondition)) {
            const value = inputCondition[key as keyof WorkflowCondition];

            if ((!Array.isArray(value) && !ValidatorFunctions.isEmpty(value)) || (Array.isArray(value) && hasLengthAtLeast(value, 1))) {
                if (!condition) {
                    condition = {};
                }
                condition[key as keyof WorkflowCondition] = value;
            }
        }

        return condition;
    }

}
