<uf-expander *ngIf="ready" class="uf-box flat">
    <div expanderHeader class="uf-app-bar flat">
        <div class="title primary">Settings</div>
        <uf-icon *ngIf="isInvalid" name="error" class="error" />
    </div>
    <div [formGroup]="control" expanderBody class="uf-grid pad-sides pad-sm-top pad-bottom">

        <uf-select *ngIf="meta.scrollTime" [options]="scrollTimes" [formControlName]="fieldKeys.ScrollTime"
            label="Scroll Time" valueProperty="value" nameProperty="name" class="col-12" />

        <uf-checkbox *ngIf="meta.isRequired" [formControlName]="fieldKeys.IsRequired" label="Required" class="col-6" />

        <uf-checkbox *ngIf="meta.isReadOnly" [formControlName]="fieldKeys.IsReadOnly" label="Read only" class="col-6" />

        <uf-checkbox *ngIf="meta.autoDetect" [formControlName]="fieldKeys.AutoDetect" label="Autodetect"
            class="col-12" />

        <uf-select *ngIf="meta.currency" [formControlName]="fieldKeys.Currency" [options]="currencies" label="Currency"
            valueProperty="identifier" nameProperty="name" class="col-12" />

        <uf-text *ngIf="meta.autofill" [formControlName]="fieldKeys.Autofill" label="Autofill" class="col-12" />

        <uf-text *ngIf="meta.bindTo" [formControlName]="fieldKeys.BindTo" label="Bind to" class="col-12" />

        <uf-textarea *ngIf="meta.showIf" [formControlName]="fieldKeys.ShowIf" label="Show if" class="col-12" />

        <uf-autocomplete *ngIf="meta.showOn" [options]="actionsResult" [formControlName]="fieldKeys.ShowOn"
            (searchChange)="searchActions($event)" label="Show on" class="col-12" />

        <uf-number *ngIf="meta.precision" [formControlName]="fieldKeys.Precision" label="Precision" class="col-12" />

        <uf-number *ngIf="meta.maxLength" [formControlName]="fieldKeys.MaxLength" label="Maximum length"
            class="col-12" />

        <uf-select *ngIf="meta.step" [formControlName]="fieldKeys.Step" [options]="intervals" label="Interval"
            valueProperty="identifier" nameProperty="name" class="col-12" />

        <uf-autocomplete *ngIf="meta.format" [formControlName]="fieldKeys.Format" [allowCustom]="true"
            [options]="formatOptions" [placeholder]="formatPlaceholder" label="Format" class="col-12" />

        <ng-container *ngIf="meta.hierarchy" [formGroupName]="fieldKeys.HierarchyConfig">
            <uf-hierarchy-unit [formControlName]="hierarchyConfigKeys.Ceiling" [activesOnly]="true"
                label="Starting Unit" placeholder="Root" class="col-12" />

            <uf-select [formControlName]="hierarchyConfigKeys.SelectionMode" [options]="selectionModeOptions"
                label="Limit selection to" valueProperty="identifier" nameProperty="name" class="col-12" />
        </ng-container>

        <uc-sort-property-input *ngIf="showSort" [formControlName]="fieldKeys.Sort" [properties]="sortProperties"
            label="Sort" class="col-12" />

    </div>
</uf-expander>