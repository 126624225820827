<div class="uf-box">
    <div class="uf-app-bar accent">
        <div class="title">
            Sort
        </div>
    </div>
    <div class="uf-grid gap">
        <uc-sort-property-input [properties]="sortableColumns" [value]="control.value"
            (valueChange)="control.setValue($event)" class="col-12" />
    </div>
</div>