import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { Modal, ModalData, ModalRuntime, ModalService, UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { AstNode, DataSourceType, FieldType, Interceptor, Option, VisibleFilterDescriptor } from '@unifii/sdk';

import { SdkInterceptor, SystemRole, UcDefinitionDataSource } from 'client';
import { flattenControls } from 'helpers/controls-helper';
import { ContextService } from 'services/context.service';

import { getDataSourceDefaults, hasFilter, hasMappings, hasSort, hasVisibleFilters, isValidTypeAndIdConfiguration } from './data-source-editor-functions';
import { DataSourceEditorService } from './data-source-editor-service';
import { DataSourceEditorStatus } from './data-source-editor-status';
import { DataSourceFormCtrl } from './data-source-form-ctrl';
import { DataSourceControlKeys, DataSourceFormModel, DataSourceMapping } from './data-source-model';

export interface DataSourceEditorData {
    fieldType: FieldType;
    fieldIdentifier: string | undefined;
    fieldId: string | undefined;
    dataSource: UcDefinitionDataSource | null;
    dataCaptures: string[];
}

@Component({
    selector: 'uc-data-source-editor',
    templateUrl: './data-source-editor.html',
    providers: [DataSourceFormCtrl, DataSourceEditorStatus, DataSourceEditorService],
})
export class DataSourceEditorComponent implements Modal<DataSourceEditorData, UcDefinitionDataSource>, OnInit, OnDestroy {

    @HostBinding('class.uc-form-card') classes = true;

    readonly dataSourceTypes = DataSourceType;
    readonly dataSourceControlKeys = DataSourceControlKeys;

    protected types: Option[];
    protected resourceOptions: Option[];
    protected isAdvancedFilter: boolean;

    constructor(
        public runtime: ModalRuntime<DataSourceEditorData, UcDefinitionDataSource>,
        @Inject(ModalData) public data: DataSourceEditorData,
        public status: DataSourceEditorStatus,
        private service: DataSourceEditorService,
        @Inject(Interceptor) private interceptor: SdkInterceptor,
        private formController: DataSourceFormCtrl,
        private context: ContextService,
        private modalService: ModalService,
    ) { }

    async ngOnInit() {
        this.interceptor.disabled = true;
        await this.status.init();
        this.types = this.service.getAllowedTypesOption();
        this.status.externalInfo = await this.service.loadExternalInfo(this.data.dataSource?.type, this.data.dataSource?.id);
        this.status.descriptor = await this.service.loadDescriptor(this.data.dataSource?.type, this.data.dataSource?.id);
        const formModel = await this.formController.mapDataToControlValue(this.data.dataSource ?? undefined);

        this.status.root = this.formController.buildRoot(formModel, this.data.dataCaptures);
        this.isAdvancedFilter = formModel.advancedFilter != null;
    }

    ngOnDestroy() {
        this.interceptor.disabled = false;
    }

    get form(): UfControlGroup {
        return this.status.root;
    }

    get dataSource(): DataSourceFormModel {
        return this.form.getRawValue() as DataSourceFormModel;
    }

    get dataSourceType(): DataSourceType | null {
        return this.form.get(DataSourceControlKeys.Type)?.value as DataSourceType | null;
    }

    get resourceControl(): UfControl {
        return this.form.get(DataSourceControlKeys.Resource) as UfControl;
    }

    get resource(): Option | null {
        return this.resourceControl.value as Option | null;
    }

    get visibleFiltersControl(): UfControl {
        return this.form.get(DataSourceControlKeys.VisibleFilters) as UfControl;
    }

    get visibleFilters(): VisibleFilterDescriptor[] {
        return this.visibleFiltersControl.value as VisibleFilterDescriptor[];
    }

    get filterControl(): UfControl {
        return this.form.get(DataSourceControlKeys.Filter) as UfControl;
    }

    get filter(): AstNode | undefined {
        return this.filterControl.value as AstNode | null ?? undefined;
    }

    get advancedFilterControl(): UfControl {
        return this.form.get(DataSourceControlKeys.AdvancedFilter) as UfControl;
    }

    get externalInputsControl(): UfControlArray {
        return this.form.get(DataSourceControlKeys.ExternalInputs) as UfControlArray;
    }

    get mappingsControl(): UfControlArray {
        return this.form.get(DataSourceControlKeys.Mappings) as UfControlArray;
    }

    get mappings(): DataSourceMapping[] {
        return this.mappingsControl.value as DataSourceMapping[];
    }

    get isSourceSelected(): boolean {
        return isValidTypeAndIdConfiguration(this.dataSourceType, this.resource?.identifier);
    }

    get showMappings(): boolean {
        return hasMappings(this.dataSourceType);
    }

    get showSort(): boolean {
        return hasSort(this.dataSourceType);
    }

    get showFilter(): boolean {
        return hasFilter(this.dataSourceType);
    }

    get showVisibleFilters(): boolean {
        return hasVisibleFilters(this.dataSourceType, this.data.fieldType);
    }

    get resourceLabel(): string | undefined {

        switch (this.dataSourceType) {
            case DataSourceType.Collection:
                return 'Collection';
            case DataSourceType.Bucket:
                return 'Form Data';
            case DataSourceType.UserClaims:
                return 'User Claim';
            case DataSourceType.External:
                return 'Data Source';
            default:
                return;
        }
    }

    get resourceLink(): string[] | undefined {

        if (!this.resource) {
            return;
        }

        switch (this.dataSourceType) {
            case DataSourceType.Collection:
                return ['../../collections', this.resource.identifier, 'definition'];
            case DataSourceType.Bucket:
                return ['../../form-data', this.resource.identifier];
            case DataSourceType.UserClaims:
                return ['../../../../user-management/user-claims', this.resource.identifier];
            case DataSourceType.External:
                if (this.context.checkRoles(SystemRole.ProjectManager)) {
                    return ['../../settings/data-sources', this.resource.identifier];
                }

                return;
            default:
                return;
        }
    }

    updateVisibleFilters(visibleFilters: VisibleFilterDescriptor[] = []) {
        this.visibleFiltersControl.setValue(visibleFilters);
    }

    close() {
        this.runtime.close();
    }

    protected save() {

        this.form.setSubmitted();

        if (!this.form.valid) {

            const items = flattenControls(this.form);

            for (const entry of items) {
                entry.control.markAsTouched();

                if (entry.control.errors != null) {
                    console.log(entry.key, entry.control.errors);
                }
            }

            return;
        }

        const dataSource = this.formController.mapControlValueToData(this.form.getRawValue() as DataSourceFormModel);

        this.runtime.close(dataSource);
    }

    protected updateFilter(filter?: AstNode) {
        this.form.get(DataSourceControlKeys.Filter)?.setValue(filter);
    }

    protected async searchResource(q: string) {
        if (!this.dataSourceType) {
            return;
        }

        this.resourceOptions = await this.service.searchResources(this.dataSourceType, q);
    }

    protected typeChanged() {
        this.resourceOptions = [];
        if (this.resource) {
            this.resourceControl.setValue(null);

            return;
        }

        void this.updateDataSourceDefaults();
    }

    protected resourceChanged() {
        void this.updateDataSourceDefaults();
    }

    protected async switchFilter() {

        const prompt = (this.isAdvancedFilter && this.dataSource.advancedFilter) ||
            (!this.isAdvancedFilter && this.dataSource.filter);

        if (prompt) {
            const decision = await this.modalService.openConfirm({
                title: 'Switch Filter Mode',
                message: 'Configuration for the current filter will be lost.',
                confirmLabel: 'Switch',
                cancelLabel: `Don't Switch`,
            });

            if (!decision) {
                return;
            }
        }

        this.isAdvancedFilter = !this.isAdvancedFilter;

        if (this.isAdvancedFilter) {
            this.filterControl.setValue(undefined);
        } else {
            this.advancedFilterControl.setValue(undefined);
        }
    }

    private async updateDataSourceDefaults() {
        this.status.externalInfo = await this.service.loadExternalInfo(this.dataSourceType, this.resource?.identifier);
        this.status.descriptor = await this.service.loadDescriptor(this.dataSourceType, this.resource?.identifier);
        const defaults = getDataSourceDefaults(this.data.fieldType, this.dataSourceType, this.resource?.identifier ?? null, this.status.mappableProperties, this.status.externalInfo);

        const mappings = defaults.mappings;
        const externalInputs = defaults.externalInputs;

        defaults.mappings = null;
        defaults.externalInputs = null;

        this.form.patchValue(defaults);

        const mappingsControl = this.form.get(DataSourceControlKeys.Mappings) as UfControlArray;

        mappingsControl.clear();
        for (const mapping of mappings ?? []) {
            mappingsControl.push(this.formController.buildMappingControl(mapping));
        }

        const externalInputsControl = this.form.get(DataSourceControlKeys.ExternalInputs) as UfControlArray;

        externalInputsControl.clear();
        for (const externalInput of externalInputs ?? []) {
            externalInputsControl.push(this.formController.buildExternalInputControl(externalInput));
        }
    }

}
