import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { FilterEntries } from '@unifii/library/common';

import { UcRoles } from 'client';
import { createFilterEntries } from 'pages/users/user-filter-entry-factory';
import { UsersTableManager } from 'pages/users/users-table-manager';
import { ContextService } from 'services/context.service';

@Component({
    providers: [
        { provide: TableContainerManager, useClass: UsersTableManager },
        { provide: FilterEntries, useFactory: createFilterEntries, deps: [UcRoles, ContextService] },
    ],
    template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="addItem()" ufSyncRoute class="accent list-md pad-none" />
        </div>
        <router-outlet />
    `,
})
export class UsersComponent {

    private route = inject(ActivatedRoute);
    private router = inject(Router);

    protected addItem() {
        void this.router.navigate(['./new'], { relativeTo: this.route });
    }

}
