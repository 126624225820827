import { Inject, Injectable } from '@angular/core';
import { CollectionItemMetadataIdentifiers, DataDescriptor, DataDescriptorClaimsType, DataDescriptorExternalType, DataDescriptorService, DataPropertyDescriptor, DataSourceIdFrom, getPropertiesMap } from '@unifii/library/common';
import { DataSourceType, FieldType, Option, objectKeys } from '@unifii/sdk';

import { UcDataSources, UcFormBucketClient, UcIntegrations, UcProject, UcUserClaims } from 'client';
import { compatibleIntegrationFields } from 'pages/workflows/field-mapping.service';
import { DataSourceTypeLabelPipe } from 'pipes/data-source-type-label.pipe';

import { DataSourceDisplayLabel, DataSourceIdLabel, isValidTypeAndIdConfiguration, mapArgType } from './data-source-editor-functions';
import { DataSourceEditorStatus } from './data-source-editor-status';
import { DataSourceEditorCache, ExternalInfo } from './data-source-model';

@Injectable()
export class DataSourceEditorService {

    constructor(
        private status: DataSourceEditorStatus,
        @Inject(DataSourceEditorCache) private cache: DataSourceEditorCache,
        private project: UcProject,
        private ucFormBucketClient: UcFormBucketClient,
        private userClaimsClient: UcUserClaims,
        private ucDataSources: UcDataSources,
        private dataDescriptorService: DataDescriptorService,
        private dataSourceTypeLabel: DataSourceTypeLabelPipe,
        private ucIntegrations: UcIntegrations,
    ) { }

    async searchResources(type: DataSourceType, q?: string): Promise<Option[]> {

        const query = q ? q.trim().toLowerCase() : undefined;

        switch (type) {

            case DataSourceType.Collection:
                if (!this.status.collections) {
                    this.status.collections = this.project.getCollections();
                }

                return (await this.status.collections)
                    .filter((i) => !query || i.name.toLowerCase().includes(query))
                    .map((i) => ({ identifier: i.identifier, name: i.name }));

            case DataSourceType.Bucket:
                return (await this.ucFormBucketClient.list({ params: { q } }))
                    .map((i) => ({ identifier: i.id, name: i.id }));

            case DataSourceType.UserClaims:
                if (!this.status.userClaims) {
                    this.status.userClaims = this.userClaimsClient.list();
                }

                return (await this.status.userClaims)
                    .filter((i) => [FieldType.Choice, FieldType.MultiChoice].includes(i.valueType) && (!query || i.label.toLowerCase().includes(query)))
                    .map((i) => ({ identifier: i.id as string, name: i.label }));

            case DataSourceType.External:
                return (await this.ucDataSources.list(q))
                    .map((i) => ({ identifier: i.id, name: i.name }));

            default:
                return [];
        }
    }

    async loadResource(type?: DataSourceType, id?: string): Promise<Option | null> {

        if (!type || !id) {
            return null;
        }

        switch (type) {
            case DataSourceType.Bucket: {
                const schema = await this.cache.getSchema(id);

                return schema ? { identifier: schema.bucket, name: schema.bucket } : null;
            }
            case DataSourceType.Collection: {
                const def = await this.cache.getCollectionDefinition(id);

                return def ? { identifier: def.identifier, name: def.label } : null;
            }
            case DataSourceType.External: {
                const ds = await this.cache.getExternalDataSource(id);

                return ds ? { identifier: ds.id ?? '', name: ds.consoleName ?? '' } : null;
            }
            case DataSourceType.UserClaims: {
                const uc = await this.cache.getUserClaimConfig(id);

                return uc ? { identifier: uc.id, name: uc.label } : null;
            }
        }

        return null;
    }

    loadDescriptor(type?: DataSourceType | null, id?: string): Promise<DataDescriptor | undefined> {

        if (!isValidTypeAndIdConfiguration(type, id)) {
            return Promise.resolve(undefined);
        }

        switch (type) {
            case DataSourceType.External:
                return id ? this.loadExternalDataDescriptor(id) : Promise.resolve(undefined);
            case DataSourceType.Bucket:
                return id ? this.dataDescriptorService.getBucketDataDescriptor(id) : Promise.resolve(undefined);
            case DataSourceType.Collection:
                return id ? this.amendCollectionDataDescriptorToSwapIdFromNumberToText(this.dataDescriptorService.getCollectionDataDescriptor(id)) : Promise.resolve(undefined);
            case DataSourceType.Company:
                return this.dataDescriptorService.getCompanyDataDescriptor();
            case DataSourceType.Users:
                return this.dataDescriptorService.getUserDataDescriptor();
            case DataSourceType.UserClaims: {
                const entries: DataPropertyDescriptor[] = [{
                    type: FieldType.Text, identifier: DataSourceIdFrom, label: DataSourceIdLabel, display: `${DataSourceIdLabel} (${DataSourceIdFrom})`,
                    asDisplay: true, asSearch: false, asSort: false, asInputFilter: false, asStaticFilter: false,
                },
                {
                    type: FieldType.Text, identifier: 'display', label: DataSourceDisplayLabel, display: `${DataSourceDisplayLabel} (display)`,
                    asDisplay: true, asSearch: false, asSort: false, asInputFilter: false, asStaticFilter: false,
                }];

                return Promise.resolve({
                    type: DataDescriptorClaimsType,
                    propertyDescriptors: entries,
                    propertyDescriptorsMap: getPropertiesMap(entries),
                });
            }
            default:
                return Promise.resolve(undefined);
        }
    }

    async loadExternalInfo(type: DataSourceType | null | undefined, id: string | undefined): Promise<ExternalInfo | undefined> {

        if (type !== DataSourceType.External || !id) {
            return;
        }

        const dataSource = await this.cache.getExternalDataSource(id);

        if (!dataSource) {
            return;
        }

        const integration = await this.cache.getIntegration(dataSource.integrationId);

        if (!integration) {
            return;
        }

        const feature = integration.provider.features.find((f) => f.id === dataSource.featureId);

        if (!feature) {
            return;
        }

        return { dataSource, feature };
    }

    getAllowedTypesOption(): Option[] {

        // TODO drop support for DataSourceType.Company
        return objectKeys(DataSourceType).filter((type) =>
            ![DataSourceType.Company, DataSourceType.Named].includes(type as DataSourceType),
        ).map((type) => ({
            identifier: type,
            name: this.dataSourceTypeLabel.transform(type as DataSourceType),
        }));
    }

    /**
     * UNIFII-7127 - Collection DDE has descriptor for 'id' of type Number, the DataSourceEditor need to output type Text for 'id'. This function amend he Collection DD
     * @param dataDescriptorPromise - Collection DDE original DataDescriptor
     * @returns amended DataDescriptor for Collection
     */
    private async amendCollectionDataDescriptorToSwapIdFromNumberToText(dataDescriptorPromise: Promise<DataDescriptor | undefined>): Promise<DataDescriptor | undefined> {
        const dataDescriptor = await dataDescriptorPromise;
        const idDescriptor = dataDescriptor?.propertyDescriptorsMap.get(CollectionItemMetadataIdentifiers.Id);

        if (!idDescriptor) {
            return dataDescriptor;
        }

        idDescriptor.type = FieldType.Text;

        return dataDescriptor;
    }

    private async loadExternalDataDescriptor(dataSourceId: string): Promise<DataDescriptor | undefined> {
        const dataSource = await this.ucDataSources.get(dataSourceId);
        const integration = await this.ucIntegrations.get(dataSource.integrationId);
        const integrationFeature = integration.integrationFeatures?.find((integrationFeatureItem) => integrationFeatureItem.id === dataSource.featureId);
        const attributes = integrationFeature?.output?.attributes;

        const descriptor: DataDescriptor = {
            type: DataDescriptorExternalType,
            propertyDescriptors: [],
            propertyDescriptorsMap: new Map<string, DataPropertyDescriptor>(),
        };

        descriptor.propertyDescriptors = attributes ? Object.keys(dataSource.outputMap).reduce<DataPropertyDescriptor[]>((descriptors, key) => {
            const outputMapValue = dataSource.outputMap[key];

            if (outputMapValue) {
                const argument = attributes[outputMapValue];

                if (argument) {
                    const dataSourceType = mapArgType(argument.kind);

                    if (dataSourceType) {
                        descriptors.push({
                            type: compatibleIntegrationFields[dataSourceType],
                            label: key,
                            display: key,
                            identifier: key,
                            asDisplay: true,
                        });
                    }
                }
            }

            return descriptors;
        }, []) : [];

        return descriptor;
    }

}
