<ng-template [ngIf]="actions">

    <div *ngFor="let action of actions.controls | asUfControlsGroups; let i = index">

        <div *ngIf="action" [formGroup]="action" class="uf-box flat">
            <uf-expander [dragDisabled]="true" [isExpanded]="true" dragItem>
                <div expanderHeader class="uf-app-bar flat">
                    <div class="title">
                        Assign {{mapActionTypeDescription(action.get(controlKeys.Type)?.value)}}
                    </div>

                    <button (click)="deleteAction(i)" type="button" title="Delete" class="uf-action">
                        <uf-icon name="delete" />
                    </button>
                </div>
                <div expanderBody class="uf-grid pad">
                    <ng-container [ngSwitch]="action.get(controlKeys.Type)?.value">

                        <ng-container *ngSwitchCase="types.AssignClaim">
                            <uf-autocomplete [formControlName]="controlKeys.Claim" [options]="filteredSourceClaims"
                                [allowCustom]="true" (searchChange)="findClaims($event)"
                                (valueChange)="changeClaimValue(action, $event)" label="Claim"
                                placeholder="Select a claim" valueProperty="type" nameProperty="type" class="col-6" />
                            <uf-claim-field *ngIf="$any(action.get(controlKeys.Claim))?.value"
                                [control]="$any(action.get(controlKeys.Value))"
                                [claimConfig]="action.get(controlKeys.Claim)?.value" class="col-6" />
                        </ng-container>

                        <ng-container *ngSwitchCase="types.AssignClaimFrom">
                            <uf-autocomplete [formControlName]="controlKeys.ClaimTo" [options]="filteredSourceClaims"
                                (searchChange)="findClaims($event)" (valueChange)="claimToValueChange(i, $event)"
                                label="Claim" nameProperty="type" placeholder="Select a claim"
                                class="col-6 gap-botton" />

                            <uf-autocomplete [formControlName]="controlKeys.Claim" [options]="filteredSourceClaims"
                                [allowCustom]="true" (searchChange)="findSourceClaims($event)"
                                (valueChange)="claimFromValueChange(i, $event)" label="From Provider Claim"
                                nameProperty="type" placeholder="Select a claim" class="col-6" />
                        </ng-container>

                        <uf-hierarchy-multi-select *ngSwitchCase="types.AssignUnit"
                            [formControlName]="controlKeys.Units" label="Hierarchy Unit" class="col-12" />

                        <uf-chips *ngSwitchCase="types.AssignSystemRole" [formControlName]="controlKeys.Roles"
                            [options]="filteredSystemRoles" (searchChange)="findSystemRoles($event)"
                            placeholder="Select a System Role" label="System Role" class="col-12" />

                        <uf-role-input *ngSwitchCase="types.AssignRole" [formControlName]="controlKeys.Roles"
                            class="col-12" />

                    </ng-container>

                </div>
            </uf-expander>
        </div>
    </div>

    <div class="uf-form-actions">
        <button [options]="actionOptions" (optionSelected)="addAction($event)" type="button" class="uf-button x-small"
            listBox nameProperty="name">
            Add
            <uf-icon name="more" class="gap-none" />
        </button>
    </div>

</ng-template>