<div class="header">
    <button (click)="close()" type="button" class="uf-action tertiary" title="Close">
        <uf-icon name="close" />
    </button>
    <h3>{{data.fieldType}} Data Source ({{data.fieldIdentifier}})</h3>
</div>

<uf-panel *ngIf="form" class="container">
    <div [formGroup]="form" class="uf-grid pad">

        <ng-container class="col-12 uf-grid">
            <uf-blockquote *ngIf="data.fieldId" icon="warningSolid"
                content="Changing this datasource could cause a mismatch in any previously submitted forms."
                class="warning col-12" />

            <uf-select [formControlName]="dataSourceControlKeys.Type" [options]="types" (valueChange)="typeChanged()"
                label="Type" placeholder="Select a type" valueProperty="identifier" nameProperty="name" class="col-6" />

            <div *ngIf="resourceLabel" class="col-6 row">
                <uf-autocomplete [label]="resourceLabel" [formControlName]="dataSourceControlKeys.Resource"
                    [options]="resourceOptions" (valueChange)="resourceChanged()"
                    (searchChange)="searchResource($event)" placeholder="Search..." nameProperty="name" class="grow" />

                <div class="gap-sm-top pad-md-top gap-left">
                    <a [routerLink]="resourceLink" [style.visibility]="resourceLink ? 'visible' : 'hidden' "
                        title="Open" target="_blank" class="uf-action tertiary">
                        <uf-icon name="open" />
                    </a>
                </div>
            </div>
        </ng-container>

        <ng-template [ngIf]="isSourceSelected">

            <!-- External -->
            <ng-container *ngIf="status.externalInfo; let ext">
                <uf-message *ngIf="ext.dataSource.description" [content]="ext.dataSource.description" icon="infoSolid"
                    class="col-12 info" />
                <uc-data-source-external-inputs [externalInputs]="externalInputsControl" class="col-12" />
            </ng-container>

            <uc-data-source-mappings *ngIf="showMappings" [mappings]="mappingsControl"
                [properties]="status.mappableProperties" [fieldIdentifier]="data.fieldIdentifier" class="col-12" />

            <uc-sort-property-input *ngIf="showSort" [properties]="status.sortableProperties"
                [formControlName]="dataSourceControlKeys.Sort" label="Sort" class="col-12 gap-top-sm" />

            <uc-find-by-property-input [properties]="status.findByProperties"
                [formControlName]="dataSourceControlKeys.FindBy" label="Find By Field" class="col-12 gap-top-sm" />

            <ng-template [ngIf]="showFilter">

                <uc-visible-filters *ngIf="showVisibleFilters" [availableFilters]="status.inputFilterProperties"
                    [filters]="visibleFilters" [parentControl]="form" (filtersChange)="updateVisibleFilters($event)"
                    class="col-12" />

                <div class="col-12 row">
                    <div class="left compact">
                        <h4 class="left">Hidden Filters</h4>
                    </div>
                    <a *ngIf="isAdvancedFilter" (click)="switchFilter()" title="Switch to standard"
                        class="uf-action tertiary right">
                        <uf-icon name="filter" />
                    </a>
                    <a *ngIf="!isAdvancedFilter" (click)="switchFilter()" title="Switch to advanced"
                        class="uf-action tertiary" right>
                        <uf-icon name="custom" />
                    </a>
                </div>

                <uc-filter-editor *ngIf="!isAdvancedFilter" [dataProperties]="status.staticFilterProperties"
                    [filter]="filter" [parentControl]="form" (filterChange)="updateFilter($event)" class="col-12" />

                <uf-textarea *ngIf="isAdvancedFilter" [formControlName]="dataSourceControlKeys.AdvancedFilter"
                    class="col-12" />
            </ng-template>

        </ng-template>
    </div>
</uf-panel>

<div class="row space-children pad">
    <button (click)="close()" type="button" class="uf-button tertiary right">Cancel</button>
    <button (click)="save()" type="button" class="uf-button primary">Save</button>
</div>