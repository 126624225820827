import { Component, Inject, Injector, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { ModalService, UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { DataSource, DataSourceType, FieldTemplate, FieldType, generateUUID } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { DataSourceEditorComponent } from 'components/field-builder/data-source-editor/data-source-editor.component';
import { DataSourceEditorCache } from 'components/field-builder/data-source-editor/data-source-model';
import { DialogsService } from 'services/dialogs.service';

import { FormEditorCache } from '../form-editor-cache';
import { FORM_EDITOR_CONSTANTS } from '../form-editor-constants';
import { FieldControlKeys, OptionControlKeys } from '../form-editor-control-keys';
import { FormEditorFormCtrl } from '../form-editor-form-ctrl';
import { FormEditorFunctions } from '../form-editor-functions';
import { AttributeParentType, FormFieldMetadata } from '../form-editor-model';
import { FormEditorStatus } from '../form-editor-status';

import { FormFieldVariationComponent } from './form-field-variation.component';

@Component({
    selector: 'uc-form-field-options',
    templateUrl: './form-field-options.html',
})
export class FormFieldOptionsComponent implements OnInit, OnDestroy {

    @Input() type: FieldType;
    @Input() meta: FormFieldMetadata;
    @Input() options: UfControlArray;
    @Input() dataSourceConfig: UfControl;
    @Input() template: UfControl;
    @Input() id: UfControl;
    @Input() identifier: UfControl;
    @Input() isReportable: UfControl;
    @Input() avoidDuplicates: UfControl;
    @Input() dataCaptures: UfControl;

    readonly fieldTypes = FieldType;
    readonly fieldKeys = FieldControlKeys;
    readonly optionKeys = OptionControlKeys;
    readonly identifierWarningLength = FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH;
    readonly identifierMaxLength = this.status.identifiersMaxLength.option;

    ready: boolean;
    expandeds: boolean[];
    dataSourceName: string | undefined; // cached computation value
    showContent: boolean;
    showAlignmentOptions: boolean;
    showReportableWarning: boolean; // show warning message if field is marked as reportable but doesn't have reportable outputFields

    private subscriptions = new Subscription();
    private optionType: AttributeParentType;

    constructor(
        private fb: FormEditorFormCtrl,
        private status: FormEditorStatus,
        private modalService: ModalService,
        private injector: Injector,
        private dialogs: DialogsService,
        @Inject(FormEditorCache) private cache: FormEditorCache,
        @Optional() private variationParent?: FormFieldVariationComponent,
    ) {
        this.optionType = this.variationParent == null ? AttributeParentType.FieldOptionType : AttributeParentType.VariationOptionType;
    }

    get dataSource(): DataSource | undefined {
        return this.dataSourceConfig?.value as DataSource;
    }

    get isInvalid(): boolean {
        return this.options.invalid || !!this.dataSourceConfig?.invalid;
    }

    get canEditDataSource(): boolean {
        return !!this.dataSource?.type && ![DataSourceType.Company, DataSourceType.Named].includes(this.dataSource.type);
    }

    async ngOnInit() {
        // DataSource
        this.dataSourceName = await FormEditorFunctions.getDataSourceName(this.cache, this.dataSource);
        this.showReportableWarning = this.computeShowReportableWarning(this.isReportable.value, this.dataSourceConfig.value);

        // Options
        this.expandeds = Array(this.options.length).fill(false);
        if ([FieldType.MultiChoice, FieldType.Choice].includes(this.type)) {
            this.subscriptions.add(this.template.valueChanges.subscribe((v) => this.updateShowFlags(v)));
            this.updateShowFlags(this.template.value);
        }

        this.subscriptions.add(this.isReportable.valueChanges.subscribe((v) => {
            this.showReportableWarning = this.computeShowReportableWarning(v, this.dataSourceConfig.value);
        }));

        this.ready = true;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    async deleteDataSource() {

        if (this.dataSourceConfig == null) {
            return;
        }

        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.dataSourceConfig.setValue(null, { emitEvent: true });
        this.dataSourceConfig.markAsTouched();
    }

    async editDataSource() {

        const dataSource = await this.modalService.openFullScreen(
            DataSourceEditorComponent, {
            fieldType: this.type,
            fieldIdentifier: this.identifier.value,
            fieldId: this.id.value,
            dataSource: this.dataSource ?? null,
            dataCaptures: this.dataCaptures.getRawValue(),
        },
            { guard: true },
            [{ provide: DataSourceEditorCache, useValue: this.cache }],
            this.injector,
        );

        if (!dataSource) {
            return;
        }

        this.dataSourceConfig.setValue(dataSource);
        this.dataSourceConfig.markAsTouched();
        this.dataSourceName = await FormEditorFunctions.getDataSourceName(this.cache, this.dataSource);
        this.showReportableWarning = this.computeShowReportableWarning(this.isReportable.value, this.dataSourceConfig.value);
    }

    getOptionLabel(option: UfControlGroup): string {

        if (this.type !== FieldType.Bool) {
            return option.get(OptionControlKeys.Name)?.value;
        }

        const identifier = option.get(OptionControlKeys.Identifier)?.value as string;
        const labelPrefix = identifier.charAt(0).toUpperCase() + identifier.substring(1, identifier.length);

        return `${labelPrefix} - ${option.get(OptionControlKeys.Name)?.value}`;
    }

    showIdentifierWarningLength(option: UfControlGroup): boolean {
        const identifierControl = option.get(OptionControlKeys.Identifier) as UfControl;
        const length = ((identifierControl.value) ?? '').length;

        return length > this.identifierWarningLength && !identifierControl.showError;
    }

    addOption() {
        this.expandeds.push(true);
        this.options.push(this.fb.buildOptionControl(this.meta, this.optionType, {
            uuid: generateUUID(),
            identifier: null as any,
            name: null as any,
        }));
    }

    async removeOption(index: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.expandeds.splice(index, 1);
        this.options.removeAt(index);
    }

    private updateShowFlags(template?: string) {

        if (!template) {
            this.showContent = false;
            this.showAlignmentOptions = false;

            return;
        }

        this.showContent = [FieldTemplate.OptionWithContent, FieldTemplate.CheckboxWithContent, FieldTemplate.RadioWithContent].includes(template as FieldTemplate);
        this.showAlignmentOptions = [FieldTemplate.OptionWithContent, FieldTemplate.CheckboxWithContent, FieldTemplate.RadioWithContent].includes(template as FieldTemplate);
    }

    private computeShowReportableWarning(isReportable: boolean, dataSource: DataSource): boolean {
        if (!isReportable || dataSource == null) {
            return false;
        }

        const outputFields = dataSource?.outputFields ?? {};

        return !Object.values(outputFields).find((o) => o.isReportable);

    }

}
